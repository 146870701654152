import facepaint from 'facepaint'
const breakpoints = [576, 768, 992, 1500]
export const mediaQuery = facepaint(
  breakpoints.map((bp) => `@media(min-width: ${bp}px)`),
)
export const COLORS = {
  base: '#4A3F3B',
  sub: '#87968B',
  white: '#FEFEFE',
  emphasis: '#5A968A',
}
