import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Wrapper from '../atoms/util/Wrapper'
import 'twin.macro'
import { Link } from 'gatsby'

const Footer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "parks" } } }
        sort: { fields: frontmatter___id, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              name_short
              hp
            }
          }
        }
      }
    }
  `).allMarkdownRemark.edges.map((edge) => edge.node.frontmatter)
  return (
    <footer tw="bg-smoke font-en lg:pt-24 pt-20 lg:pb-11 pb-9">
      <Wrapper>
        <div tw="lg:flex justify-between">
          <p tw="lg:text-3xl text-2xl lg:pb-0 pb-4">
            SHIBUYA <br />
            with PARK ＆ STREET
          </p>
          <div tw="flex lg:text-xl text-base">
            <div tw="flex flex-col">
              <Link to="/" tw="lg:mt-5 mt-3">
                ABOUT
              </Link>
              <Link to="/parkstreet/" tw="lg:mt-5 mt-3">
                PARK&STREET
              </Link>
              <a href="mailto:info@play-shibuya.com" tw="lg:mt-5 mt-3">
                CONTACT
              </a>
            </div>
            <div tw="flex flex-col ml-28">
              {data.map((item) => (
                <a
                  href={item.hp}
                  key={item.name_short}
                  rel="noopener noreferrer"
                  target="_blank"
                  tw="lg:mt-5 mt-3"
                >
                  {item.name_short}
                </a>
              ))}
            </div>
          </div>
        </div>
        <p tw="font-en text-center lg:mt-36 mt-28" lang="en">
          &copy; SHIBUYA with PARK ＆ STREET {new Date().getFullYear()}
        </p>
      </Wrapper>
    </footer>
  )
}

export default Footer
