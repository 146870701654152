import React, { useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import HamburgerMenu from 'react-hamburger-menu'
import Wrapper from '../atoms/util/Wrapper'
import 'twin.macro'
const SubPageHeader: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Wrapper>
      <header tw="lg:height[200px] height[60px] flex justify-between items-center">
        <Link to="/">
          <div tw="mx-auto lg:w-max w-36">
            <StaticImage
              src="../../../static/images/logo_b.png"
              alt="サイトロゴ"
            />
          </div>
        </Link>
        <div tw="font-en text-black text-xl lg:block hidden">
          <Link to="/" tw="ml-12">
            ABOUT
          </Link>
          <Link to="/parkstreet/" tw="ml-12">
            PARK&STREET
          </Link>
          <a href="mailto:info@play-shibuya.com" tw="ml-12">
            CONTACT
          </a>
        </div>
        <div tw="flex flex-col items-center z-50 lg:hidden">
          <HamburgerMenu
            isOpen={isOpen}
            menuClicked={() => setIsOpen(!isOpen)}
            width={23}
            height={14}
            color={'#000'}
          />
          <p
            css={{ fontSize: 10, transform: 'scale(0.75)' }}
            tw="font-bold text-black tracking-widest pl-0.5"
          >
            MENU
          </p>
        </div>
        <nav
          css={{
            position: 'fixed',
            top: 0,
            right: isOpen ? 0 : '-100vw',
            width: '100vw',
            height: '100vh',
            background: 'rgba(57,125,172,0.9)',
          }}
          tw="z-40 duration-300 text-white text-4xl flex flex-col items-center tracking-widest pt-56"
        >
          <Link to="/">ABOUT</Link>
          <Link to="/parkstreet/" tw="mt-10">
            PARK＆STREET
          </Link>
          <a href="mailto:info@play-shibuya.com" tw="mt-10">
            CONTACT
          </a>
        </nav>
      </header>
    </Wrapper>
  )
}

export default SubPageHeader
