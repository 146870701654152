import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GlobalStyles } from 'twin.macro'
import Header from '@/components/organisms/Header'
import SubPageHeader from '@/components/organisms/SubPageHeader'
import Footer from '@/components/organisms/Footer'
import '@fontsource/noto-sans-jp'
import '@fontsource/noto-sans-jp/700.css'

const Layout: React.FC<{ path: string }> = ({ children, path, ...rest }) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <div {...rest} tw="font-ja lg:text-lg text-base">
      <GlobalStyles />
      {path === '/' ? <Header /> : <SubPageHeader />}
      <main>{children}</main>
      <button
        onClick={scrollTop}
        tw="fixed bg-black lg:w-20 w-12 lg:h-20 h-12 bottom-5 right-5 flex items-center justify-center rounded-full"
      >
        <StaticImage
          src="../../static/images/arrow_t.png"
          alt="↑"
          tw="lg:w-auto w-5"
        />
      </button>
      <Footer />
    </div>
  )
}

export default Layout
